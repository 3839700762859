/* img.head-shot {
	margin: 0 auto;
	height: 240px;
	width: 240px;
	display: block;
	border: 1px solid rgba(115, 104, 92, 1) !important;
	--tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
} */

.article-social li:not(:last-child) .react-share__ShareButton:after {
	content: '-';
	margin: 0 -4px 0 4px;
	text-transform: none !important;
}

.gr_grid_container {
	@apply grid grid-cols-4 gap-2;
}

.post a.nav,
a.special {
	@apply text-theme-yellow;
}

.post a {
	@apply text-blue-900;
}

.post a:hover,
a.special:hover {
	@apply underline;
}

.post img.images {
	--tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
	box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--tw-shadow) !important;
	box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),
		var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}
.post img {
	--tw-border-opacity: 1;
	border: 2px solid rgba(115, 104, 92, var(--tw-border-opacity)) !important;
	--tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);

	--tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
	--tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
	@apply rounded-sm;
}

.post a.more {
	@apply text-theme-yellow hover:text-theme-brown;
}

.post header a {
	@apply mb-4 mt-4 font-light tracking-wider  text-theme-brown hover:no-underline;
}

.post h1 a.title {
	@apply font-normal normal-case;
}

.picture-frame {
	border-image-source: url('/images/border.webp') !important;
	border-image-slice: 2 !important;
	border-image-width: 1 !important;
	border-image-outset: 1 !important;
}

img.head-shot {
	margin: 0 auto;
	width: 240px;
	height: 240px;
	display: block;
	border: 1px solid rgba(115, 104, 92, 1) !important;
	--tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
	@apply mx-auto h-60 w-60 rounded-full border-theme-brown-muted shadow-md;
}

.footnote_tooltip {
	@apply invisible absolute -mt-8 rounded bg-gray-100 p-1 text-red-500 shadow-lg;
}

.footnote_referrer:hover .footnote_tooltip {
	@apply visible z-50;
}

.wp-block-image {
	display: inline-block;
	margin: 0 auto;
}

figcaption {
	font-size: 0.85em !important;
	text-align: right;
}

.aligncenter > img {
	margin: 0 auto;
	display: block;
}

blockquote {
	margin-left: 1em;
	padding-left: 1em;
	border-left: 3px solid hsl(0deg 0% 25% / 61%);
}

.button[disabled],
.button[aria-disabled='true'] {
	transform: translateY(0);
	pointer-events: none;
	opacity: 0.7;
}

.form-validation-error {
	margin: 0;
	margin-top: 0.25em;
	color: var(--color-invalid);
	font-size: 0.8rem;
}

.error-container {
	background-color: hsla(356, 77%, 59%, 0.747);
	border-radius: 0.25rem;
	padding: 0.5rem 1rem;
}

[aria-invalid='true'] {
	@apply border-red-800;
}

[role='alert'] {
	@apply text-red-800;
}

.bg-bridge {
	background-image: url(https://whiletheyweresleeping.mo.cloudinary.net/site/bridge.png);
}
